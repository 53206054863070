<template>
  <div class="md:my-24">
    <h1 class="mb-1 text-xl font-semibold text-gray-800">Reset Password</h1>
    <p class="font-light text-sm">
      Enter your new password below to change your password
    </p>
    <p v-if="error.state" class="text-xs text-red-500 my-2 font-semibold">
      {{ error.message }}
    </p>
    <p class="text-xs text-green-500 my-2 font-semibold" v-if="message != ''">
      {{ message }}
    </p>
    <div class="mt-4">
      <label class="leading-relaxed" for="password">Password</label>
      <t-input
        id="password"
        v-model="password"
        name="password"
        type="password"
        placeholder="New Password"
        :disabled="loading"
      ></t-input>
    </div>
    <div class="mt-4">
      <label class="leading-relaxed" for="c-password">Confirm Password</label>
      <t-input
        id="c-password"
        v-model="cPassword"
        name="c-password"
        type="password"
        placeholder="Confirm New Password"
        :disabled="loading"
      ></t-input>
    </div>
    <div class="mt-4">
      <t-button
        @click="resetPassword"
        class="w-full flex space-x-4 justify-center items-center"
      >
        <span>Reset Password</span>
        <spinner v-if="loading"></spinner>
      </t-button>
    </div>
    <hr class="my-8" />
    <p class="mt-4">
      <router-link
        class="text-sm font-medium text-amber-500 hover:underline"
        :to="{ name: 'Login' }"
      >
        Back to Login
      </router-link>
    </p>
  </div>
</template>

<script>
import authApi from '../../api/auth'
import Spinner from '../../components/Utils/Spinner.vue'

export default {
  components: { Spinner },
  name: 'ResetPassword',
  data() {
    return {
      password: '',
      cPassword: '',
      error: {
        state: false,
        message: ''
      },
      message: '',
      loading: false
    }
  },
  created() {
    this.email = this.$route.query.email
    this.token = this.$route.params.token
  },
  methods: {
    async resetPassword() {
      this.loading = true
      this.error.state = false

      if (this.password !== this.cPassword) {
        this.error.state = true
        this.error.message = 'Password do not match!'
        this.loading = false
        return
      }

      const res = await authApi.auth().resetPassword({
        token: this.token,
        password: this.password,
        confirmPassword: this.cPassword
      })

      if (res.error) {
        this.error.state = true
        this.error.message = res.errorMessage
        this.loading = false
        return
      }
      this.error.state = false

      this.message = res.message
      this.loading = false
      setTimeout(() => {
        this.$router.push({ name: 'Login' })
      }, 3000)
    }
  }
}
</script>

<style></style>
